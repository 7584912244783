<template>
  <div class="wrapper">

      <!-- Alert Component -->
      <CAlert
          color="danger"
          :show.sync="alertDanger"
          closeButton
      >
        Acción No Realizada.
        <CProgress
          color="info"
          :max="dismissSecs"
          :value="alertDanger"
          height="4px"
        />
      </CAlert>

      <CAlert
          color="success"
          :show.sync="alertSuccess"
          closeButton
      >
        Acción Realizada con Éxito.
        <CProgress
          color="info"
          :max="dismissSecs"
          :value="alertSuccess"
          height="4px"
        />
      </CAlert>

      <!-- Lista Component -->
      <div>
          <CRow>
            <CCol col>
              <CCard>
                <CCardHeader>
                  <CIcon name="cil-justify-center"/>
                  <strong> Eventos</strong>
                  <div class="card-header-actions">
                      <CButton color="primary" @click="nuevoEvento()" class="mr-1">
                        Agregar Evento
                      </CButton>
                  </div>
                </CCardHeader>
                <CCardBody>

                  <CDataTable
                    :items="arrayEventos"
                    :fields="fields"
                    column-filter
                    table-filter
                    items-per-page-select
                    :items-per-page="10"
                    hover
                    sorter
                    pagination
                  >
                    <template #estado="{item}">
                      <td>
                          <div v-if="item.status === 1">
                            Activo
                          </div>
                          <div v-else style="color:red;">
                            Inactivo
                          </div>
                      </td>
                    </template>
                      <template #acciones="{item}">
                          <td>
                            <CBadge>
                              <CButton @click="obtenerEvento(item.id, item.name, item.starts_at, item.starts_end, item.description)" color="success" shape="pill">Editar</CButton>
                              <CButton
                                color="danger"
                                variant="outline"
                                shape="pill"
                                @click="editarEstado(item.id, item.status)"
                              >
                                {{Boolean(item.status) ? 'Desactivar' : 'Activar'}}
                              </CButton>
                            </CBadge>
                          </td>
                      </template>
                      
                  </CDataTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
      </div>

    <!-- Modal Component -->
      <CModal
        :show.sync="modalEvento"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="primary">
          <CRow>
            <CCol sm="12">
              <CInput
                label="Nombre"
                placeholder="Ingresar nombre del evento"
                id="modal_name"
                v-model="modal_name"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
                  <CInput
                    label="Inicio"
                    type="date"
                    id="modal_start"
                    v-model="modal_start"
                  />
            </CCol>
            <CCol sm="6">
                  <CInput
                    label="Fin"
                    type="date"
                    id="modal_end"
                    v-model="modal_end"
                  />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
                  <CTextarea
                    label="Descripción"
                    placeholder="..."
                    rows="5"
                    id="modal_description"
                    v-model="modal_description"
                  />
            </CCol>
          </CRow>
          <template #header>
            <h6 class="modal-title" id="modalEvento_h6_titulo"></h6>
            <CButtonClose @click="modalEvento = false" class="text-white"/>
          </template>
          <template #footer>
            <CButton @click="modalEvento = false" color="danger">Cancelar</CButton>
            <CButton @click="registrarEvento()" color="success" id="modalEvento_btn_guardar"></CButton>
          </template>
      </CModal>

  </div>
</template>

<script>


import $ from 'jquery'

import axios from 'axios'

const fields = [
  { key: 'id', label: 'ID', _style:'width:1%' },
  { key: 'name', label: 'Evento', _style:'min-width:200px' },
  { key: 'description', label: 'Descripción' },
  { key: 'starts_at', label: 'Inicio' },
  { key: 'starts_end', label: 'Fin' },
  { key: 'estado', label: 'Estado', _style: 'width:1%' },
  { key: 'acciones', label: 'Acciones', _style: 'width:1%', sorter: false, filter: false }
]

let cod_evento=0;

export default{
  name: 'Inicio',
  data(){
      return{
          dismissSecs: 5,
          alertDanger: 0,
          alertSuccess: 0,
          arrayEventos:[],
          fields,
          modalEvento: false,
          togglePress: true,
          modal_name: '',
          modal_start: '',
          modal_end: '',
          modal_description: ''
      }
  },
  methods:{
      countDownChanged (alertSuccess) {
          this.alertSuccess = alertSuccess
      },
      countDownChanged (alertDanger) {
          this.alertDanger = alertDanger
      },
      nuevoEvento(){
          $('#modalEvento_h6_titulo').html('Registrar Nuevo Evento');
          $('#modalEvento_btn_guardar').html('Registrar');
          this.modal_name = '';
          this.modal_description = '';
          this.modal_start = '';
          this.modal_end = '';
          cod_evento=0;
          this.modalEvento = true;
      },
      obtenerEvento(id, name, starts_at, starts_end, description){
          $('#modalEvento_h6_titulo').html('Editar Evento');
          $('#modalEvento_btn_guardar').html('Editar');
          this.modal_name = name;
          this.modal_start = starts_at;
          this.modal_end = starts_end;
          this.modal_description = description;
          cod_evento=id;
          this.modalEvento = true;
      },
      listarEventos(){
        let vue=this;
        axios.get('v1/events')
            .then( function(response){
               vue.arrayEventos=response.data['result'];
              })
      },
      editarEstado(id, status){
          let me = this;
          if(parseInt(status)===0){
              axios.put('v1/events/activated', {
                  'id': id
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.listarEventos();
                      me.alertSuccess = 5;
                  } else {
                      me.alertDanger = 5;
                  }
              }).catch(function (error) {
                  console.log(error);
              });
          }
          if(parseInt(status)===1){
              axios.put('v1/events/disabled', {
                  'id': id
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.listarEventos();
                      me.alertSuccess = 5;
                  } else {
                      me.alertDanger = 5;
                  }
              }).catch(function (error) {
                  console.log(error);
              });
          }
      },
      validarEvento() {
          let me = this;
          me.errorEvento = false;
          $("#modal_name").css("border", "");
          $("#modal_start").css("border", "");
          $("#modal_end").css("border", "");

          if (!(parseInt(me.modal_name.length) > 0)){
              $("#modal_name").css("border", "1px solid red");
              console.log("falta modal_name");
              me.errorEvento = true;
            }
          if (!(parseInt(me.modal_start.length) > 0)){
              $("#modal_start").css("border", "1px solid red");
              console.log("falta modal_start");
              me.errorEvento = true;
            }
          if (!(parseInt(me.modal_end.length) > 0)){
              $("#modal_end").css("border", "1px solid red");
              console.log("falta modal_end");
              me.errorEvento = true;
            }

          return me.errorSelection;
      },
      registrarEvento() {
          if (this.validarEvento()) {
              return;
          }

          let me = this;

          if(parseInt(cod_evento)===0){
              axios.post('v1/events', {
                  'name': this.modal_name,
                  'starts_at': this.modal_start,
                  'starts_end': this.modal_end,
                  'description': this.modal_description
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.modal_name = '';
                      me.modal_description = '';
                      me.modal_start = '';
                      me.modal_end = '';
                      me.modalEvento = false;
                      me.alertSuccess = 5;
                      me.listarEventos();
                  } else {
                      me.alertDanger = 5;
                  }

              }).catch(function (error) {
                  console.log(error);
              });
          }
          if(parseInt(cod_evento)>0){
              axios.put('v1/events', {
                  'id': cod_evento,
                  'name': this.modal_name,
                  'starts_at': this.modal_start,
                  'starts_end': this.modal_end,
                  'description': this.modal_description
              }).then(function (response) {
                  if (parseInt(response.data.http_code) === 200) {
                      me.modal_name = '';
                      me.modal_description = '';
                      me.modal_start = '';
                      me.modal_end = '';
                      me.modalEvento = false;
                      me.alertSuccess = 5;
                      me.listarEventos();
                  } else {
                      me.alertDanger = 5;
                  }

              }).catch(function (error) {
                  console.log(error);
              });
          }
      }
  },
  mounted(){
    this.listarEventos()
  }
};

</script>

<style>
.post{
  border: 1px solid #D9D9D9;
  padding: 20px;
  margin-bottom: 10px;

  .btn {
  margin-bottom: 4px;
  margin-right: 6px;
}
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
    margin-right: 8px;
  }
  .c-icon-sm {
    margin-right: 5px;
  }
  .c-icon-lg {
    margin-right: 12px;
  }
}
}
</style>